import React, { useEffect, useState } from "react";
import { Routes } from "../routes";
import { createStackNavigator } from "@react-navigation/stack";
import { AppTabNavigator } from "./app-tab.navigator";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  selectUserId,
  selectUserStudyId,
  userSlice,
} from "../../store/user.slice";
import { useLazyUsersControllerGetProfileQuery } from "../../services/api-service-sub-services/users-api-service";
import { GlobalLoading } from "../../modules/core/screens/global-loading";
import { ProfileSetupContainer } from "../../modules/profile/profile-setup.container";
import { ProfileSetupScreen } from "../../modules/profile/profile-setup.screen";
import { SinglePostContainer } from "../../modules/posts/single-post.container";
import { SinglePostScreen } from "../../modules/posts/single-post.screen";
import { useLogout } from "../../modules/core/utils/hooks/use-logout";
import { OnboardingSurveyContainer } from "../../modules/surveys/onboarding-survey.container";
import { OnboardingSurveyScreen } from "../../modules/surveys/onboarding-survey.screen";
import {
  ParticipantProfileWithPropertiesDto,
  useLazyStudiesControllerGetProfilesQuery,
  useLazyStudiesControllerGetStudyQuery,
  useLazyStudiesControllerGetTreatmentArmQuery,
} from "../../services/api-service-sub-services/studies-api-service";
import { appSlice } from "../../store/app.slice";
import { useAnalyticsUpdater } from "../../modules/core/utils/hooks/use-analytics-updater";
import { InAppBrowserContainer } from "../../modules/in-app-browser/in-app-browser.container";
import { InAppBrowserScreen } from "../../modules/in-app-browser/in-app-browser.screen";
import { notificationSlice } from "../../store/notification.slice";
import { ExitSurveyContainer } from "../../modules/surveys/exit-survey.container";
import { ExitSurveyScreen } from "../../modules/surveys/exit-survey.screen";
import { PendingContainer } from "../../modules/pending/pending.container";
import { PendingScreen } from "../../modules/pending/pending.screen";
import { NavigatorScreenParams } from "@react-navigation/native";
import { NewPostContainer } from "../../modules/posts/new-post.container";
import { NewPostScreen } from "../../modules/posts/new-post.screen";
import { BotProfileContainer } from "../../modules/profile/bot-profile.container";
import { BotProfileScreen } from "../../modules/profile/bot-profile.screen";

export type AppStackParamList = {
  [Routes.APP_STACK__PROFILE_SETUP]: undefined;
  [Routes.APP_STACK__ONBOARDING_SURVEY]: undefined;
  [Routes.APP_STACK__EXIT_SURVEY]: undefined;
  [Routes.APP_STACK__MAIN]: NavigatorScreenParams<any>;
  [Routes.APP_STACK__SINGLE_POST]: {
    postId: string;
    highlightedCommentId?: string;
  };
  [Routes.APP_STACK__IN_APP_BROWSER]: {
    url: string;
  };
  [Routes.APP_STACK__PENDING]: undefined;
  [Routes.APP_STACK__CREATE_POST]: undefined;
  [Routes.APP_STACK__BOT_PROFILE]: {
    profile: ParticipantProfileWithPropertiesDto | string;
  };
};

const Stack = createStackNavigator<AppStackParamList>();

const ProfileSetup = ProfileSetupContainer(ProfileSetupScreen);
const SinglePost = SinglePostContainer(SinglePostScreen);
const OnboardingSurvey = OnboardingSurveyContainer(OnboardingSurveyScreen);
const ExitSurvey = ExitSurveyContainer(ExitSurveyScreen);
const InAppBrowser = InAppBrowserContainer(InAppBrowserScreen);
const Pending = PendingContainer(PendingScreen);
const NewPost = NewPostContainer(NewPostScreen);
const BotProfile = BotProfileContainer(BotProfileScreen);

export const AppStackNavigator = () => {
  const { logout } = useLogout();

  const [isReady, setIsReady] = useState<boolean>(false);
  const [initialRouteName, setInitialRouteName] =
    useState<keyof AppStackParamList>();

  const userId = useAppSelector(selectUserId);
  const studyId = useAppSelector(selectUserStudyId);
  const [getUserProfile] = useLazyUsersControllerGetProfileQuery({
    selectFromResult: () => ({}),
  });
  const [getTreatmentArm] = useLazyStudiesControllerGetTreatmentArmQuery({
    selectFromResult: () => ({}),
  });
  const [getStudy] = useLazyStudiesControllerGetStudyQuery({
    selectFromResult: () => ({}),
  });
  const [getSimulatedProfiles] = useLazyStudiesControllerGetProfilesQuery();

  const dispatch = useAppDispatch();

  useAnalyticsUpdater();

  const load = async () => {
    try {
      if (!studyId) {
        return;
      }

      const study = await getStudy({ id: studyId }).unwrap();
      dispatch(appSlice.actions.studyReceived(study));
      const profile = await getUserProfile({ id: userId! }).unwrap();

      // save the simulated profiles to the store
      const simulatedProfiles = await getSimulatedProfiles({
        id: studyId,
        type: "simulated",
        treatmentArmId: profile.treatmentArm,
      }).unwrap();

      dispatch(appSlice.actions.simulatedProfilesReceived(simulatedProfiles));

      dispatch(
        notificationSlice.actions.setNotificationsStatus(
          !!study.isNotificationsEnabled
        )
      );

      // if the user has not set-up a profile, then redirect to profile setup page
      if (profile?.properties.length! === 0) {
        setInitialRouteName(Routes.APP_STACK__PROFILE_SETUP);
      } else {
        dispatch(userSlice.actions.userProfileFetched(profile));
        // if  there's not a profile treatment arm, then redirect to the survey screen
        if (!profile.treatmentArm) {
          setInitialRouteName(Routes.APP_STACK__ONBOARDING_SURVEY);
        } else {
          // ?? TODO check if the user has answered the survey.. some arm assignent does not require them to take the
          // onboarding survey before they are assigned a treatment arm
          const treatmentArm = await getTreatmentArm({
            id: studyId,
            treatmentArmId: profile.treatmentArm,
          }).unwrap();
          dispatch(appSlice.actions.treatmentArmReceived(treatmentArm));
          setInitialRouteName(Routes.APP_STACK__MAIN);
        }
      }
      setIsReady(true);
    } catch (e) {
      await logout();
    }
  };

  useEffect(() => {
    if (studyId) {
      load();
    }
  }, [studyId]);

  if (!isReady) {
    return <GlobalLoading />;
  }

  return (
    <Stack.Navigator
      initialRouteName={initialRouteName}
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name={Routes.APP_STACK__PROFILE_SETUP}
        component={ProfileSetup}
      />
      <Stack.Screen
        name={Routes.APP_STACK__ONBOARDING_SURVEY}
        component={OnboardingSurvey}
      />
      <Stack.Screen
        name={Routes.APP_STACK__EXIT_SURVEY}
        component={ExitSurvey}
      />
      <Stack.Screen name={Routes.APP_STACK__PENDING} component={Pending} />
      <Stack.Screen name={Routes.APP_STACK__MAIN} component={AppTabNavigator} />
      <Stack.Screen name={Routes.APP_STACK__CREATE_POST} component={NewPost} />
      <Stack.Screen
        name={Routes.APP_STACK__SINGLE_POST}
        component={SinglePost}
      />
      <Stack.Screen
        name={Routes.APP_STACK__IN_APP_BROWSER}
        component={InAppBrowser}
      />
      <Stack.Screen
        name={Routes.APP_STACK__BOT_PROFILE}
        component={BotProfile}
      />
    </Stack.Navigator>
  );
};
