import { persistor, useAppDispatch } from "../../../../store/store";
import { logoutAction } from "../../../../store/app.slice";
import { api } from "../../../../services/api-service";
import { usePostHog } from "posthog-react-native";

export const useLogout = () => {
  const posthog = usePostHog();
  const dispatch = useAppDispatch();

  return {
    logout: async () => {
      posthog?.reset();
      dispatch(logoutAction());
      dispatch(api.util?.resetApiState());
      await persistor.purge();
    },
  };
};
