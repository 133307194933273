import React from "react";
import { Center, HStack, Spinner, Text, VStack } from "native-base";
import WebView from "react-native-webview";
import { Platform } from "react-native";
import { ShouldStartLoadRequest } from "react-native-webview/lib/WebViewTypes";

export type ExitSurveyScreenProps = {
  qualtricsSurveyURL?: string;
  isLoading: boolean;
  handleUrlRedirects: (request: ShouldStartLoadRequest) => boolean;
};

export const ExitSurveyScreen = (props: ExitSurveyScreenProps) => {
  return (
    <VStack flex="1" bg="xLightGrey" safeAreaTop={true} justifyContent="center">
      {(!props.qualtricsSurveyURL || props.isLoading) && (
        <Center>
          <HStack space="2" alignItems="center">
            <Spinner size="sm" color="black" />
            <Text variant="small" color="black">
              Loading...
            </Text>
          </HStack>
        </Center>
      )}

      {props.qualtricsSurveyURL &&
        Platform.OS !== "web" &&
        !props.isLoading && (
          <WebView
            incognito={true}
            source={{ uri: props.qualtricsSurveyURL }}
            style={{ flex: 1 }}
            androidLayerType="software"
            onShouldStartLoadWithRequest={props.handleUrlRedirects}
          />
        )}
      {props.qualtricsSurveyURL && Platform.OS === "web" && (
        <iframe src={props.qualtricsSurveyURL} style={{ flex: 1 }} />
      )}
    </VStack>
  );
};
