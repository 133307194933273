import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "./store";
import {
  ConfiguredPopupDto,
  ContentVariantEntity,
  GetStudyServiceResponseDto,
  InteractionVariantEntity,
  ParticipantProfileWithPropertiesWithUserDto,
  ParticipantProfileWithPropertiesWithUserDtoPaginatedReturn,
  PostReturnDto,
  ProfileVariantEntity,
  TreatmentArmWithPostsResponseDto,
} from "../services/api-service-sub-services/studies-api-service";
import pkg from "../../package.json";
import _ from "lodash";
import { extractFullName } from "../modules/profile/utils/profile.util";

const APP_SLICE_NAME = "app";

export type AppSlice = {
  version: string;
  study?: GetStudyServiceResponseDto;
  treatmentArm?: TreatmentArmWithPostsResponseDto;
  // user configured settings, or enforced by app after some conditions
  features?: {
    notifications?: {
      status?: "enabled" | "disabled";
    };
  };
  experience: {
    onboardingInstructions: string;
    interactionVariants: InteractionVariantEntity[];
    profileVariants: ProfileVariantEntity[];
    contentVariants: ContentVariantEntity[];
    prePopulatedPosts: PostReturnDto[];
    timeBasedPopups: ConfiguredPopupDto[];
    reactivePopups: ConfiguredPopupDto[];
  };
  simulatedProfiles: ParticipantProfileWithPropertiesWithUserDto[];
};

const initialState: AppSlice = {
  version: pkg.version,
  study: undefined,
  treatmentArm: undefined,
  features: {
    notifications: {
      status: "enabled",
    },
  },
  experience: {
    onboardingInstructions: "",
    interactionVariants: [],
    profileVariants: [],
    contentVariants: [],
    prePopulatedPosts: [],
    timeBasedPopups: [],
    reactivePopups: [],
  },
  simulatedProfiles: [],
};

export const appSlice = createSlice({
  name: APP_SLICE_NAME,
  initialState: initialState,
  reducers: {
    simulatedProfilesReceived: (
      state,
      action: PayloadAction<ParticipantProfileWithPropertiesWithUserDtoPaginatedReturn>
    ) => {
      state.simulatedProfiles = _.uniqBy(
        [...state.simulatedProfiles, ...action.payload.docs],
        "_id"
      );
    },
    notificationFeatureConfigurationReceived: (
      state,
      action: PayloadAction<{ status: "enabled" | "disabled" }>
    ) => {
      if (!state.features || _.isEmpty(state.features)) {
        state.features = {
          notifications: {},
        };
      }
      state.features.notifications = {
        ...action.payload,
      };
    },
    studyReceived: (
      state,
      action: PayloadAction<GetStudyServiceResponseDto>
    ) => {
      state.study = action.payload;
    },
    treatmentArmReceived: (
      state,
      action: PayloadAction<TreatmentArmWithPostsResponseDto>
    ) => {
      // set the treatment arm name
      state.treatmentArm = action.payload;

      // set the instructions if there is an onboarding instruction attached to a treatment arm
      if (action.payload.instructions) {
        state.experience.onboardingInstructions = action.payload.instructions;
      }
      if (action.payload.interactionVariants) {
        state.experience.interactionVariants =
          action.payload.interactionVariants;
      }
      if (action.payload.profileVariants) {
        state.experience.profileVariants = action.payload.profileVariants;
      }
      if (action.payload.contentVariants) {
        state.experience.contentVariants = action.payload.contentVariants;
      }
      if (action.payload.prePopulatedPosts) {
        state.experience.prePopulatedPosts =
          action.payload.prePopulatedPosts.map((item) => item.post);
      }
      if (action.payload.configuredPopups) {
        state.experience.timeBasedPopups =
          action.payload.configuredPopups.filter(
            (popup: ConfiguredPopupDto) => popup.type === "time-based"
          );
        state.experience.reactivePopups =
          action.payload.configuredPopups.filter(
            (popup: ConfiguredPopupDto) => popup.type === "reactive"
          );
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutAction, (state) => {
      state = initialState;
      return state;
    });
  },
});

export const logoutAction = createAction(appSlice.name + "/logout");

// Define selectors alongside reducers:
// See: https://redux.js.org/usage/deriving-data-selectors#define-selectors-alongside-reducers
export const selectAppVersion = (state: RootState) => state.app.version;

export const selectExperienceInstructions = (state: RootState) =>
  state.app.experience.onboardingInstructions;

export const selectExperienceInteractionVariants = (state: RootState) =>
  state.app.experience.interactionVariants;
export const selectExperienceProfileVariants = (state: RootState) =>
  state.app.experience.profileVariants;
export const selectExperienceContentVariants = (state: RootState) =>
  state.app.experience.contentVariants;

export const selectExperiencePrePopulatedPosts = (state: RootState) =>
  state.app.experience.prePopulatedPosts;

export const selectStudy = (state: RootState) => state.app.study;

export const selectNotificationFeatureConfiguration = (state: RootState) =>
  state.app.features?.notifications;

export const selectTreatmentArmExperience = (state: RootState) =>
  state.app.experience;

export const selectTreatmentArmTitle = (state: RootState) =>
  state.app.treatmentArm?.title || "";

export const selectTreatmentArmEvergreenTime = (state: RootState) =>
  state.app.treatmentArm?.evergreenTime;

export const selectPostPromptPlaceholderText = (state: RootState) =>
  state.app.treatmentArm?.postPromptPlaceholderText ||
  "Share your thoughts here...";

export const selectPostSubmitIconUrl = (state: RootState) =>
  state.app.treatmentArm?.postSubmitIconUrl;

export const selectSimulatedProfiles = createSelector(
  (state: RootState) => state.app.simulatedProfiles,
  (simulatedProfiles) =>
    simulatedProfiles.map(
      (profile: ParticipantProfileWithPropertiesWithUserDto) => {
        return {
          _id: profile._id,
          username: extractFullName(profile.properties),
          properties: profile.properties,
        };
      }
    )
);

export const selectTreatmentArmReactivePopups = (state: RootState) =>
  state.app.experience.reactivePopups;
