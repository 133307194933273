import { api } from "../api-service";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    studiesControllerGetStudies: build.query<
      StudiesControllerGetStudiesApiResponse,
      StudiesControllerGetStudiesRequest
    >({
      query: (queryArg) => ({
        url: `/studies`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          search: queryArg.search,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
          status: queryArg.status,
        },
      }),
    }),
    studiesControllerCreateStudy: build.mutation<
      StudiesControllerCreateStudyApiResponse,
      StudiesControllerCreateStudyRequest
    >({
      query: (queryArg) => ({
        url: `/studies`,
        method: "POST",
        body: queryArg.createStudyDto,
      }),
    }),
    studiesControllerPatchStudy: build.mutation<
      StudiesControllerPatchStudyApiResponse,
      StudiesControllerPatchStudyRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.createStudyDto,
      }),
    }),
    studiesControllerGetStudy: build.query<
      StudiesControllerGetStudyApiResponse,
      StudiesControllerGetStudyRequest
    >({
      query: (queryArg) => ({ url: `/studies/${queryArg.id}` }),
    }),
    studiesControllerDeleteStudy: build.mutation<
      StudiesControllerDeleteStudyApiResponse,
      StudiesControllerDeleteStudyRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    studiesControllerDuplicate: build.mutation<
      StudiesControllerDuplicateApiResponse,
      StudiesControllerDuplicateRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/duplicate`,
        method: "POST",
      }),
    }),
    studiesControllerGetStudyParticipants: build.query<
      StudiesControllerGetStudyParticipantsApiResponse,
      StudiesControllerGetStudyParticipantsRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/participants`,
        params: { page: queryArg.page, limit: queryArg.limit },
      }),
    }),
    studiesControllerGetParticipantsDataByStudy: build.query<
      StudiesControllerGetParticipantsDataByStudyApiResponse,
      StudiesControllerGetParticipantsDataByStudyRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/participants/data`,
      }),
    }),
    studiesControllerGetParticipantsDataByStudyInCsv: build.query<
      StudiesControllerGetParticipantsDataByStudyInCsvApiResponse,
      StudiesControllerGetParticipantsDataByStudyInCsvRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/participants/data/csv`,
      }),
    }),
    studiesControllerFindStudyResearcherPosts: build.query<
      StudiesControllerFindStudyResearcherPostsApiResponse,
      StudiesControllerFindStudyResearcherPostsRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/researcher-posts`,
      }),
    }),
    studiesControllerResetResearcherPosts: build.mutation<
      StudiesControllerResetResearcherPostsApiResponse,
      StudiesControllerResetResearcherPostsRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/researcher-posts/reset`,
        method: "POST",
        body: queryArg.resetResearcherPostsDto,
      }),
    }),
    studiesControllerHandleExportAnalytics: build.query<
      StudiesControllerHandleExportAnalyticsApiResponse,
      StudiesControllerHandleExportAnalyticsRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/export-analytics`,
      }),
    }),
    studiesControllerHandleOverride: build.mutation<
      StudiesControllerHandleOverrideApiResponse,
      StudiesControllerHandleOverrideRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/surveys-v2/override`,
        method: "POST",
        body: queryArg.createStudySurveyResponseOverrideDto,
      }),
    }),
    studiesControllerCreateStudySurvey: build.mutation<
      StudiesControllerCreateStudySurveyApiResponse,
      StudiesControllerCreateStudySurveyRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/surveys`,
        method: "POST",
        body: queryArg.createSurveyDto,
      }),
    }),
    studiesControllerGetStudySurveys: build.query<
      StudiesControllerGetStudySurveysApiResponse,
      StudiesControllerGetStudySurveysRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/surveys`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          type: queryArg["type"],
        },
      }),
    }),
    studiesControllerGetSurvey: build.query<
      StudiesControllerGetSurveyApiResponse,
      StudiesControllerGetSurveyRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/surveys/${queryArg.surveyId}`,
      }),
    }),
    studiesControllerUpdateSurvey: build.mutation<
      StudiesControllerUpdateSurveyApiResponse,
      StudiesControllerUpdateSurveyRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/surveys/${queryArg.surveyId}`,
        method: "PUT",
        body: queryArg.updateSurveyDto,
      }),
    }),
    studiesControllerDeleteStudySurvey: build.mutation<
      StudiesControllerDeleteStudySurveyApiResponse,
      StudiesControllerDeleteStudySurveyRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/surveys/${queryArg.surveyId}`,
        method: "DELETE",
      }),
    }),
    studiesControllerCreateStudySurveyResponse: build.mutation<
      StudiesControllerCreateStudySurveyResponseApiResponse,
      StudiesControllerCreateStudySurveyResponseRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/surveys/${queryArg.surveyId}/responses`,
        method: "POST",
        body: queryArg.createSurveyResponseDto,
      }),
    }),
    studiesControllerGetStudySurveyResponses: build.query<
      StudiesControllerGetStudySurveyResponsesApiResponse,
      StudiesControllerGetStudySurveyResponsesRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/surveys/${queryArg.surveyId}/responses`,
        params: { page: queryArg.page, limit: queryArg.limit },
      }),
    }),
    studiesControllerGetProfiles: build.query<
      StudiesControllerGetProfilesApiResponse,
      StudiesControllerGetProfilesRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/profiles`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          type: queryArg["type"],
          search: queryArg.search,
          treatmentArmId: queryArg.treatmentArmId,
        },
      }),
    }),
    studiesControllerGenerateSimulatedProfiles: build.mutation<
      StudiesControllerGenerateSimulatedProfilesApiResponse,
      StudiesControllerGenerateSimulatedProfilesRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/profiles`,
        method: "POST",
        body: queryArg.generateSimulatedProfilesDto,
      }),
    }),
    studiesControllerUpdateProfile: build.mutation<
      StudiesControllerUpdateProfileApiResponse,
      StudiesControllerUpdateProfileRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/profiles/${queryArg.profileId}`,
        method: "PATCH",
        body: queryArg.updateProfileDto,
      }),
    }),
    studiesControllerDeleteStudyProfile: build.mutation<
      StudiesControllerDeleteStudyProfileApiResponse,
      StudiesControllerDeleteStudyProfileRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/profiles/${queryArg.profileId}`,
        method: "DELETE",
      }),
    }),
    studiesControllerPreviewPopulatedPost: build.mutation<
      StudiesControllerPreviewPopulatedPostApiResponse,
      StudiesControllerPreviewPopulatedPostRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/posts/preview-populated`,
        method: "POST",
        body: queryArg.previewPopulatedPostsDto,
      }),
    }),
    studiesControllerPreviewPostsDestination: build.mutation<
      StudiesControllerPreviewPostsDestinationApiResponse,
      StudiesControllerPreviewPostsDestinationRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/posts/preview-destination`,
        method: "POST",
        body: queryArg.previewPostsDestinationDto,
      }),
    }),
    studiesControllerCreatePost: build.mutation<
      StudiesControllerCreatePostApiResponse,
      StudiesControllerCreatePostRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/posts`,
        method: "POST",
        body: queryArg.createPostDto,
      }),
    }),
    studiesControllerFindAllPosts: build.query<
      StudiesControllerFindAllPostsApiResponse,
      StudiesControllerFindAllPostsRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/posts`,
        params: {
          profileIds: queryArg.profileIds,
          tags: queryArg.tags,
          excludeIds: queryArg.excludeIds,
          isMadeByResearcher: queryArg.isMadeByResearcher,
          search: queryArg.search,
          labels: queryArg.labels,
          page: queryArg.page,
          limit: queryArg.limit,
          profile: queryArg.profile,
          myProfile: queryArg.myProfile,
          type: queryArg["type"],
          exclude: queryArg.exclude,
        },
      }),
    }),
    studiesControllerFindPostById: build.query<
      StudiesControllerFindPostByIdApiResponse,
      StudiesControllerFindPostByIdRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/posts/${queryArg.postId}`,
        params: { myProfile: queryArg.myProfile },
      }),
    }),
    studiesControllerUpdatePost: build.mutation<
      StudiesControllerUpdatePostApiResponse,
      StudiesControllerUpdatePostRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/posts/${queryArg.postId}`,
        method: "PATCH",
        body: queryArg.createPostDto,
      }),
    }),
    studiesControllerDeletePost: build.mutation<
      StudiesControllerDeletePostApiResponse,
      StudiesControllerDeletePostRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/posts/${queryArg.postId}`,
        method: "DELETE",
      }),
    }),
    studiesControllerFindPostCommentsByPostId: build.query<
      StudiesControllerFindPostCommentsByPostIdApiResponse,
      StudiesControllerFindPostCommentsByPostIdRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/posts/${queryArg.postId}/comments`,
        params: {
          isMadeByResearcher: queryArg.isMadeByResearcher,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    studiesControllerCreateCommentForPost: build.mutation<
      StudiesControllerCreateCommentForPostApiResponse,
      StudiesControllerCreateCommentForPostRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/posts/${queryArg.postId}/comments`,
        method: "POST",
        body: queryArg.createCommentDto,
      }),
    }),
    studiesControllerUpdateCommentForPost: build.mutation<
      StudiesControllerUpdateCommentForPostApiResponse,
      StudiesControllerUpdateCommentForPostRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/posts/${queryArg.postId}/comments/${queryArg.commentId}`,
        method: "PATCH",
        body: queryArg.updateCommentDto,
      }),
    }),
    studiesControllerDeleteComment: build.mutation<
      StudiesControllerDeleteCommentApiResponse,
      StudiesControllerDeleteCommentRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/posts/${queryArg.postId}/comments/${queryArg.commentId}`,
        method: "DELETE",
      }),
    }),
    studiesControllerUpsertInteractionForPost: build.mutation<
      StudiesControllerUpsertInteractionForPostApiResponse,
      StudiesControllerUpsertInteractionForPostRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/posts/${queryArg.postId}/interactions`,
        method: "PATCH",
        body: queryArg.createInteractionDto,
      }),
    }),
    studiesControllerCreateTreatmentArm: build.mutation<
      StudiesControllerCreateTreatmentArmApiResponse,
      StudiesControllerCreateTreatmentArmRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/treatment-arms`,
        method: "POST",
        body: queryArg.createTreatmentArmDto,
      }),
    }),
    studiesControllerGetTreatmentArms: build.query<
      StudiesControllerGetTreatmentArmsApiResponse,
      StudiesControllerGetTreatmentArmsRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/treatment-arms`,
        params: { page: queryArg.page, limit: queryArg.limit },
      }),
    }),
    studiesControllerGetTreatmentArm: build.query<
      StudiesControllerGetTreatmentArmApiResponse,
      StudiesControllerGetTreatmentArmRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/treatment-arms/${queryArg.treatmentArmId}`,
        params: {
          shouldFilterPostsByHiddenArmTags:
            queryArg.shouldFilterPostsByHiddenArmTags,
        },
      }),
    }),
    studiesControllerUpdateTreatmentArm: build.mutation<
      StudiesControllerUpdateTreatmentArmApiResponse,
      StudiesControllerUpdateTreatmentArmRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/treatment-arms/${queryArg.treatmentArmId}`,
        method: "PUT",
        body: queryArg.createTreatmentArmDto,
      }),
    }),
    studiesControllerDeleteTreatmentArm: build.mutation<
      StudiesControllerDeleteTreatmentArmApiResponse,
      StudiesControllerDeleteTreatmentArmRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/treatment-arms/${queryArg.treatmentArmId}`,
        method: "DELETE",
      }),
    }),
    studiesControllerUpdateTreatmentArmsDistributions: build.mutation<
      StudiesControllerUpdateTreatmentArmsDistributionsApiResponse,
      StudiesControllerUpdateTreatmentArmsDistributionsRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/treatment-arms/distributions`,
        method: "PATCH",
        body: queryArg.updateTreatmentArmDistributionDto,
      }),
    }),
    studiesControllerJoinStudy: build.mutation<
      StudiesControllerJoinStudyApiResponse,
      StudiesControllerJoinStudyRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/join`,
        method: "POST",
        body: queryArg.joinStudyDto,
      }),
    }),
    studiesControllerGetStudyParticipantCodes: build.query<
      StudiesControllerGetStudyParticipantCodesApiResponse,
      StudiesControllerGetStudyParticipantCodesRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/participant-codes`,
        params: {
          status: queryArg.status,
          search: queryArg.search,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    studiesControllerGenerateParticipantCodes: build.mutation<
      StudiesControllerGenerateParticipantCodesApiResponse,
      StudiesControllerGenerateParticipantCodesRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/participant-codes/generate`,
        method: "POST",
        body: queryArg.generateParticipantCodesDto,
      }),
    }),
    studiesControllerGetParticipantCodesAsCsv: build.query<
      StudiesControllerGetParticipantCodesAsCsvApiResponse,
      StudiesControllerGetParticipantCodesAsCsvRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/participant-codes/csv`,
      }),
    }),
    studiesControllerCreateStudyBotPrompt: build.mutation<
      StudiesControllerCreateStudyBotPromptApiResponse,
      StudiesControllerCreateStudyBotPromptRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/bots/prompts`,
        method: "POST",
        body: queryArg.createBotPromptDto,
      }),
    }),
    studiesControllerGetStudyBotPrompts: build.query<
      StudiesControllerGetStudyBotPromptsApiResponse,
      StudiesControllerGetStudyBotPromptsRequest
    >({
      query: (queryArg) => ({ url: `/studies/${queryArg.id}/bots/prompts` }),
    }),
    studiesControllerUpdateBotPrompt: build.mutation<
      StudiesControllerUpdateBotPromptApiResponse,
      StudiesControllerUpdateBotPromptRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/bots/prompts/${queryArg.promptId}`,
        method: "PATCH",
        body: queryArg.updateBotPromptDto,
      }),
    }),
    studiesControllerDeleteBotPrompt: build.mutation<
      StudiesControllerDeleteBotPromptApiResponse,
      StudiesControllerDeleteBotPromptRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/bots/prompts/${queryArg.promptId}`,
        method: "DELETE",
      }),
    }),
    studiesControllerCreateBotPost: build.mutation<
      StudiesControllerCreateBotPostApiResponse,
      StudiesControllerCreateBotPostRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/bots/posts`,
        method: "POST",
      }),
    }),
    studiesControllerCreateBotComment: build.mutation<
      StudiesControllerCreateBotCommentApiResponse,
      StudiesControllerCreateBotCommentRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/bots/comments`,
        method: "POST",
      }),
    }),
    studiesControllerGenerateFollowingAction: build.mutation<
      StudiesControllerGenerateFollowingActionApiResponse,
      StudiesControllerGenerateFollowingActionRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/bots/following`,
        method: "POST",
      }),
    }),
    studiesControllerGenerateBotInteraction: build.mutation<
      StudiesControllerGenerateBotInteractionApiResponse,
      StudiesControllerGenerateBotInteractionRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/bots/interactions`,
        method: "POST",
      }),
    }),
    studiesControllerGetParticipantCode: build.query<
      StudiesControllerGetParticipantCodeApiResponse,
      StudiesControllerGetParticipantCodeRequest
    >({
      query: (queryArg) => ({
        url: `/studies/${queryArg.id}/codes/${queryArg.code}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type StudiesControllerGetStudiesApiResponse =
  /** status 200 The requested paginated `Study` documents are returned. */ StudyPaginatedReturn;
export type StudiesControllerGetStudiesRequest = {
  /** The page number to retrieve. */
  page?: number;
  /** The number of documents to retrieve. */
  limit?: number;
  search?: string;
  sortBy?: "_id" | "title" | "startDate" | "endDate" | "tags";
  sortOrder?: "ASC" | "DSC";
  status?: "all" | "in-progress" | "completed" | "draft" | "archived";
};
export type StudiesControllerCreateStudyApiResponse =
  /** status 201 Study created */ CreateStudyResponseDto;
export type StudiesControllerCreateStudyRequest = {
  /** Optional create study values. Not needed when researcher creates study from the app */
  createStudyDto: CreateStudyDto;
};
export type StudiesControllerPatchStudyApiResponse =
  /** status 200 Study successfully patched */ PatchStudyResponseDto;
export type StudiesControllerPatchStudyRequest = {
  id: string;
  createStudyDto: CreateStudyDto;
};
export type StudiesControllerGetStudyApiResponse =
  /** status 200 Study Document */ GetStudyServiceResponseDto;
export type StudiesControllerGetStudyRequest = {
  id: string;
};
export type StudiesControllerDeleteStudyApiResponse =
  /** status 200  */ ApiOkResponseDto;
export type StudiesControllerDeleteStudyRequest = {
  id: string;
};
export type StudiesControllerDuplicateApiResponse = /** status 201  */ Study;
export type StudiesControllerDuplicateRequest = {
  id: string;
};
export type StudiesControllerGetStudyParticipantsApiResponse =
  /** status 200 The requested paginated `GetStudyParticipantsResponseDTO` documents are returned. */ GetStudyParticipantsResponseDtoPaginatedReturn;
export type StudiesControllerGetStudyParticipantsRequest = {
  id: string;
  /** The page number to retrieve. */
  page?: number;
  /** The number of documents to retrieve. */
  limit?: number;
};
export type StudiesControllerGetParticipantsDataByStudyApiResponse =
  /** status 200  */ StudyParticipantDataDto[];
export type StudiesControllerGetParticipantsDataByStudyRequest = {
  id: string;
};
export type StudiesControllerGetParticipantsDataByStudyInCsvApiResponse =
  /** status 200 CSV file response */ undefined;
export type StudiesControllerGetParticipantsDataByStudyInCsvRequest = {
  id: string;
};
export type StudiesControllerFindStudyResearcherPostsApiResponse = unknown;
export type StudiesControllerFindStudyResearcherPostsRequest = {
  id: string;
};
export type StudiesControllerResetResearcherPostsApiResponse =
  /** status 201  */ boolean;
export type StudiesControllerResetResearcherPostsRequest = {
  id: string;
  resetResearcherPostsDto: ResetResearcherPostsDto;
};
export type StudiesControllerHandleExportAnalyticsApiResponse = unknown;
export type StudiesControllerHandleExportAnalyticsRequest = {
  id: string;
};
export type StudiesControllerHandleOverrideApiResponse =
  /** status 201  */ ApiCreatedResponseDto;
export type StudiesControllerHandleOverrideRequest = {
  id: string;
  createStudySurveyResponseOverrideDto: CreateStudySurveyResponseOverrideDto;
};
export type StudiesControllerCreateStudySurveyApiResponse =
  /** status 201  */ GetStudySurveyDto;
export type StudiesControllerCreateStudySurveyRequest = {
  id: string;
  createSurveyDto: CreateSurveyDto;
};
export type StudiesControllerGetStudySurveysApiResponse =
  /** status 200 The requested paginated `GetStudySurveyDTO` documents are returned. */ GetStudySurveyDtoPaginatedReturn;
export type StudiesControllerGetStudySurveysRequest = {
  id: string;
  /** The page number to retrieve. */
  page?: number;
  /** The number of documents to retrieve. */
  limit?: number;
  type?: "onboarding" | "exit";
};
export type StudiesControllerGetSurveyApiResponse =
  /** status 200  */ GetStudySurveyDto;
export type StudiesControllerGetSurveyRequest = {
  id: string;
  surveyId: string;
};
export type StudiesControllerUpdateSurveyApiResponse =
  /** status 200  */ GetStudySurveyDto;
export type StudiesControllerUpdateSurveyRequest = {
  id: string;
  surveyId: string;
  updateSurveyDto: UpdateSurveyDto;
};
export type StudiesControllerDeleteStudySurveyApiResponse =
  /** status 200  */ ApiOkResponseDto;
export type StudiesControllerDeleteStudySurveyRequest = {
  id: string;
  surveyId: string;
};
export type StudiesControllerCreateStudySurveyResponseApiResponse =
  /** status 201 Survey response successfully created. */ SurveyResponse;
export type StudiesControllerCreateStudySurveyResponseRequest = {
  id: string;
  surveyId: string;
  createSurveyResponseDto: CreateSurveyResponseDto;
};
export type StudiesControllerGetStudySurveyResponsesApiResponse =
  /** status 200 The requested paginated `GetSurveyResponseDTO` documents are returned. */ GetSurveyResponseDtoPaginatedReturn;
export type StudiesControllerGetStudySurveyResponsesRequest = {
  id: string;
  surveyId: string;
  /** The page number to retrieve. */
  page?: number;
  /** The number of documents to retrieve. */
  limit?: number;
};
export type StudiesControllerGetProfilesApiResponse =
  /** status 200 The requested paginated `ParticipantProfileWithPropertiesWithUserDTO` documents are returned. */ ParticipantProfileWithPropertiesWithUserDtoPaginatedReturn;
export type StudiesControllerGetProfilesRequest = {
  id: string;
  /** The page number to retrieve. */
  page?: number;
  /** The number of documents to retrieve. */
  limit?: number;
  type?: "all" | "simulated" | "real";
  search?: string;
  treatmentArmId?: string;
};
export type StudiesControllerGenerateSimulatedProfilesApiResponse =
  /** status 201  */ ParticipantProfileValue[];
export type StudiesControllerGenerateSimulatedProfilesRequest = {
  id: string;
  generateSimulatedProfilesDto: GenerateSimulatedProfilesDto;
};
export type StudiesControllerUpdateProfileApiResponse =
  /** status 200  */ ParticipantProfileWithPropertiesDto;
export type StudiesControllerUpdateProfileRequest = {
  id: string;
  profileId: string;
  updateProfileDto: UpdateProfileDto;
};
export type StudiesControllerDeleteStudyProfileApiResponse =
  /** status 200  */ ApiOkResponseDto;
export type StudiesControllerDeleteStudyProfileRequest = {
  id: string;
  profileId: string;
};
export type StudiesControllerPreviewPopulatedPostApiResponse =
  /** status 201  */ PostReturnDto[];
export type StudiesControllerPreviewPopulatedPostRequest = {
  id: string;
  previewPopulatedPostsDto: PreviewPopulatedPostsDto;
};
export type StudiesControllerPreviewPostsDestinationApiResponse =
  /** status 201 CSV file response */ undefined;
export type StudiesControllerPreviewPostsDestinationRequest = {
  id: string;
  previewPostsDestinationDto: PreviewPostsDestinationDto;
};
export type StudiesControllerCreatePostApiResponse =
  /** status 201 OK */ PostReturnDto;
export type StudiesControllerCreatePostRequest = {
  /** The `id` of the Study. */
  id: string;
  /** The Post resource. */
  createPostDto: CreatePostDto;
};
export type StudiesControllerFindAllPostsApiResponse =
  /** status 200 The requested paginated `PostReturnDTO` documents are returned. */ PostReturnDtoPaginatedReturn;
export type StudiesControllerFindAllPostsRequest = {
  id: string;
  /** Comma separated profile ids to only include posts these profile ids authored */
  profileIds?: string;
  tags?: string;
  excludeIds?: string;
  isMadeByResearcher?: boolean;
  /** Optional search term for posts */
  search?: string;
  /** Optional comma separated string to filter post labels */
  labels?: string;
  /** The page number to retrieve. */
  page?: number;
  /** The number of documents to retrieve. */
  limit?: number;
  profile?: string;
  myProfile?: string;
  type?: "all" | "simulated" | "real";
  exclude?: string;
};
export type StudiesControllerFindPostByIdApiResponse =
  /** status 200 OK */ PostReturnDto;
export type StudiesControllerFindPostByIdRequest = {
  /** The `id` of the Study. */
  id: string;
  /** The `id` of the Post. */
  postId: string;
  myProfile?: string;
};
export type StudiesControllerUpdatePostApiResponse =
  /** status 200 OK */ PostReturnDto;
export type StudiesControllerUpdatePostRequest = {
  id: string;
  postId: string;
  createPostDto: CreatePostDto;
};
export type StudiesControllerDeletePostApiResponse =
  /** status 200  */ ApiOkResponseDto;
export type StudiesControllerDeletePostRequest = {
  id: string;
  postId: string;
};
export type StudiesControllerFindPostCommentsByPostIdApiResponse =
  /** status 200 The requested paginated `CommentReturnDto` documents are returned. */ CommentReturnDtoPaginatedReturn;
export type StudiesControllerFindPostCommentsByPostIdRequest = {
  /** The `id` of the Post. */
  postId: string;
  /** Filter comments whether they are made by researcher or not */
  isMadeByResearcher?: boolean;
  /** The page number to retrieve. */
  page?: number;
  /** The number of documents to retrieve. */
  limit?: number;
  /** The `id` of the Study. */
  id: any;
};
export type StudiesControllerCreateCommentForPostApiResponse =
  /** status 201 OK */ CommentReturnDto;
export type StudiesControllerCreateCommentForPostRequest = {
  /** The `id` of the Study. */
  id: string;
  /** The `id` of the Post. */
  postId: string;
  /** The Comment resource. */
  createCommentDto: CreateCommentDto;
};
export type StudiesControllerUpdateCommentForPostApiResponse =
  /** status 200  */ Comment2;
export type StudiesControllerUpdateCommentForPostRequest = {
  id: string;
  postId: string;
  commentId: string;
  updateCommentDto: UpdateCommentDto;
};
export type StudiesControllerDeleteCommentApiResponse =
  /** status 200  */ ApiOkResponseDto;
export type StudiesControllerDeleteCommentRequest = {
  id: string;
  postId: string;
  commentId: string;
};
export type StudiesControllerUpsertInteractionForPostApiResponse =
  /** status 200  */ Interaction;
export type StudiesControllerUpsertInteractionForPostRequest = {
  id: string;
  postId: string;
  createInteractionDto: CreateInteractionDto;
};
export type StudiesControllerCreateTreatmentArmApiResponse =
  /** status 201  */ TreatmentArm;
export type StudiesControllerCreateTreatmentArmRequest = {
  id: string;
  createTreatmentArmDto: CreateTreatmentArmDto;
};
export type StudiesControllerGetTreatmentArmsApiResponse =
  /** status 200 The requested paginated `TreatmentArmWithParticipantsCountDTO` documents are returned. */ TreatmentArmWithParticipantsCountDtoPaginatedReturn;
export type StudiesControllerGetTreatmentArmsRequest = {
  id: string;
  /** The page number to retrieve. */
  page?: number;
  /** The number of documents to retrieve. */
  limit?: number;
};
export type StudiesControllerGetTreatmentArmApiResponse =
  /** status 200  */ TreatmentArmWithPostsResponseDto;
export type StudiesControllerGetTreatmentArmRequest = {
  id: string;
  treatmentArmId: string;
  shouldFilterPostsByHiddenArmTags?: boolean;
};
export type StudiesControllerUpdateTreatmentArmApiResponse =
  /** status 200  */ TreatmentArm;
export type StudiesControllerUpdateTreatmentArmRequest = {
  id: string;
  treatmentArmId: string;
  createTreatmentArmDto: CreateTreatmentArmDto;
};
export type StudiesControllerDeleteTreatmentArmApiResponse =
  /** status 200  */ ApiOkResponseDto;
export type StudiesControllerDeleteTreatmentArmRequest = {
  id: string;
  treatmentArmId: string;
};
export type StudiesControllerUpdateTreatmentArmsDistributionsApiResponse =
  /** status 200  */ TreatmentArm[];
export type StudiesControllerUpdateTreatmentArmsDistributionsRequest = {
  id: string;
  updateTreatmentArmDistributionDto: UpdateTreatmentArmDistributionDto;
};
export type StudiesControllerJoinStudyApiResponse =
  /** status 201  */ ParticipantProfile;
export type StudiesControllerJoinStudyRequest = {
  id: string;
  joinStudyDto: JoinStudyDto;
};
export type StudiesControllerGetStudyParticipantCodesApiResponse =
  /** status 200 The requested paginated `ParticipantCodeResponseDto` documents are returned. */ ParticipantCodeResponseDtoPaginatedReturn;
export type StudiesControllerGetStudyParticipantCodesRequest = {
  id: string;
  /** Filter codes by status */
  status?: "available" | "used" | "expired";
  /** Search term for codes */
  search?: string;
  /** The page number to retrieve. */
  page?: number;
  /** The number of documents to retrieve. */
  limit?: number;
};
export type StudiesControllerGenerateParticipantCodesApiResponse =
  /** status 201  */ ParticipantCode[];
export type StudiesControllerGenerateParticipantCodesRequest = {
  id: string;
  generateParticipantCodesDto: GenerateParticipantCodesDto;
};
export type StudiesControllerGetParticipantCodesAsCsvApiResponse =
  /** status 200 CSV file response */ undefined;
export type StudiesControllerGetParticipantCodesAsCsvRequest = {
  id: string;
};
export type StudiesControllerCreateStudyBotPromptApiResponse =
  /** status 201  */ BotPrompt;
export type StudiesControllerCreateStudyBotPromptRequest = {
  id: string;
  createBotPromptDto: CreateBotPromptDto;
};
export type StudiesControllerGetStudyBotPromptsApiResponse =
  /** status 200  */ BotPrompt[];
export type StudiesControllerGetStudyBotPromptsRequest = {
  id: string;
};
export type StudiesControllerUpdateBotPromptApiResponse =
  /** status 200  */ BotPrompt[];
export type StudiesControllerUpdateBotPromptRequest = {
  promptId: string;
  id: string;
  updateBotPromptDto: UpdateBotPromptDto;
};
export type StudiesControllerDeleteBotPromptApiResponse =
  /** status 200  */ BotPrompt[];
export type StudiesControllerDeleteBotPromptRequest = {
  promptId: string;
  id: string;
};
export type StudiesControllerCreateBotPostApiResponse =
  /** status 201  */ PostReturnDto;
export type StudiesControllerCreateBotPostRequest = {
  id: string;
};
export type StudiesControllerCreateBotCommentApiResponse =
  /** status 201  */ CommentReturnDto;
export type StudiesControllerCreateBotCommentRequest = {
  id: string;
};
export type StudiesControllerGenerateFollowingActionApiResponse =
  /** status 201  */ Following;
export type StudiesControllerGenerateFollowingActionRequest = {
  id: string;
};
export type StudiesControllerGenerateBotInteractionApiResponse = unknown;
export type StudiesControllerGenerateBotInteractionRequest = {
  id: string;
};
export type StudiesControllerGetParticipantCodeApiResponse =
  /** status 200  */ ParticipantCode;
export type StudiesControllerGetParticipantCodeRequest = {
  id: string;
  code: string;
};
export type CharacteristicModifierEntity = {
  _id?: string;
  name: string;
  value: string;
};
export type CharacteristicEntity = {
  _id?: string;
  name: string;
  displayName?: string;
  pool: string[];
  modifiers: CharacteristicModifierEntity[];
  icon?: string;
  isCustomInteraction?: boolean;
  isUpdating?: boolean;
};
export type Study = {
  _id: string;
  title: string;
  shortName: string;
  startDate: string;
  endDate: string;
  duration: string;
  brandColor?: string;
  researchers: string[];
  tags: string[];
  invitation?: string;
  createdBy: string;
  status: "draft" | "inactive" | "active" | "archived";
  createdAt: string;
  characteristics: CharacteristicEntity[];
  botsEnabled?: boolean;
  isNotificationsEnabled?: boolean;
  onboardingSurveyId?: string;
  exitSurveyId?: string;
  onboardingSurveyRespondentsCount?: number;
  exitSurveyRespondentsCount?: number;
  currentOnboardingSurveySubscriptionId?: string;
  currentExitSurveySubscriptionId?: string;
  isDuplicating?: boolean;
  previousStatus?: "draft" | "inactive" | "active" | "archived";
  theming?: object;
  logoUrl?: string;
};
export type StudyPaginatedReturn = {
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page?: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage?: number;
  nextPage?: number;
  docs: Study[];
};
export type CreateStudyResponseDto = {
  study: Study;
  message: string;
};
export type CreateStudyDto = {
  _id?: string;
  title?: string;
  shortName?: string;
  startDate?: string;
  endDate?: string;
  duration?: string;
  brandColor?: string;
  researchers?: string[];
  tags?: string[];
  invitation?: string;
  createdBy?: string;
  status?: "draft" | "inactive" | "active" | "archived";
  createdAt?: string;
  characteristics?: CharacteristicEntity[];
  botsEnabled?: boolean;
  isNotificationsEnabled?: boolean;
  onboardingSurveyId?: string;
  exitSurveyId?: string;
  isDuplicating?: boolean;
  theming?: object;
  logoUrl?: string;
  currentExitSurveySubscriptionId?: string;
  currentOnboardingSurveySubscriptionId?: string;
  exitSurveyRespondentsCount?: number;
  onboardingSurveyRespondentsCount?: number;
  previousStatus?: "draft" | "inactive" | "active" | "archived";
};
export type PatchStudyResponseDto = {
  message: string;
};
export type SanitizedUserDto = {
  _id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  roles: ("ADMIN" | "RESEARCHER" | "PARTICIPANT")[];
  profilePicture?: string;
  currentStudy?: string;
  currentTreatmentArm?: string;
  university?: string;
  deviceIds?: string[];
  createdAt: string;
  updatedAt: string;
};
export type ObjectId = {};
export type Invitation = {
  _id: string;
  code: string;
  study: ObjectId;
};
export type GetStudyServiceResponseDto = {
  _id: string;
  title: string;
  shortName: string;
  startDate: string;
  endDate: string;
  duration: string;
  brandColor?: string;
  tags: string[];
  createdBy: string;
  status: "draft" | "inactive" | "active" | "archived";
  createdAt: string;
  characteristics: CharacteristicEntity[];
  botsEnabled?: boolean;
  isNotificationsEnabled?: boolean;
  onboardingSurveyId?: string;
  exitSurveyId?: string;
  onboardingSurveyRespondentsCount?: number;
  exitSurveyRespondentsCount?: number;
  currentOnboardingSurveySubscriptionId?: string;
  currentExitSurveySubscriptionId?: string;
  isDuplicating?: boolean;
  previousStatus?: "draft" | "inactive" | "active" | "archived";
  theming?: object;
  logoUrl?: string;
  researchers?: SanitizedUserDto[];
  invitation: Invitation;
};
export type ApiOkResponseDto = {
  statusCode: number;
  message: string;
};
export type ApiUnauthorizedResponseDto = {
  statusCode: number;
  message: string;
};
export type ApiNotFoundResponseDto = {
  statusCode: number;
  message: string;
};
export type GetStudyParticipantsResponseDto = {
  createdAt: string;
  invitation: string;
  updatedAt: string;
  user: SanitizedUserDto;
  _id: string;
};
export type GetStudyParticipantsResponseDtoPaginatedReturn = {
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page?: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage?: number;
  nextPage?: number;
  docs: GetStudyParticipantsResponseDto[];
};
export type StudyParticipantDataDto = {
  sessionId: string;
  treatmentArm: string;
  participantCode: string;
  profilePicture: string;
  firstName: string;
  lastName: string;
  bio: string;
  postOrCommentId: string;
  userContent: number;
  staticContent: number;
  dynamicContent: number;
  postOrCommentText: number;
  postDate: string;
  postLikes: number;
  postDislikes: number;
  participantLiked: number;
  participantDisliked: number;
  parentPost: string;
  previousCommentOrPost: string;
};
export type InteractionVariantsEntity = {
  _id?: string;
  variant: string;
  action: string;
  count: number;
};
export type RelativeTime = {
  amount: number;
  unit: "months" | "weeks" | "days" | "hours" | "minutes";
};
export type TaggedProfilesEntity = {
  _id?: string;
  profileId: string;
  matcher: string;
};
export type Comment = {
  date: string;
  user?: string;
  profile: string;
  content: string;
  postInstance?: string;
  mediaUrls: string[];
  isMadeByResearcher?: boolean;
  taggedProfiles?: TaggedProfilesEntity[];
  _id?: string;
};
export type Post = {
  date?: string;
  user?: string;
  onlyForUser?: string;
  profile: string;
  content: string;
  tags?: string[];
  link?: string;
  likes?: number;
  dislikes?: number;
  interactionVariants?: InteractionVariantsEntity[];
  mediaUrls: string[];
  relativeTime?: RelativeTime;
  isMadeByResearcher?: boolean;
  labels?: string[];
  highlight?: string | null;
  customInteractionsCount?: object;
  duplicatedFromId?: string;
  taggedProfiles?: TaggedProfilesEntity[];
  _id?: string;
  comments: Comment[];
};
export type ResetResearcherPostsDto = {
  posts: Post[];
};
export type ApiCreatedResponseDto = {
  statusCode: number;
  message: string;
};
export type CreateStudySurveyResponseOverrideDto = {
  surveyType: "pre-treatment" | "post-treatment";
  participantCode: string;
  studyId?: string;
};
export type SurveyQuestionCondition = {
  answerIndex: number;
  action: string;
  value: number;
};
export type GetStudySurveyQuestionDto = {
  questionType:
    | "single-choice"
    | "multiple-choice"
    | "text-entry"
    | "text-graphic";
  questionText: string;
  format:
    | "single-select"
    | "multi-select"
    | "single-select-with-other"
    | "multi-select-with-other"
    | "dropdown"
    | "dropdown-with-other"
    | "single-line"
    | "multiple-lines"
    | "essay-text-box"
    | "text"
    | "graphic"
    | "text-&-graphic";
  options?: string[];
  sortOrder?: number;
  image?: string;
  randomizeQuestionOptions?: boolean;
  required?: boolean;
  section: number;
  conditions: SurveyQuestionCondition[];
  _id: string;
};
export type SurveySection = {
  prev?: number;
  next?: number;
  title: string;
  description: string;
  randomized: boolean;
};
export type GetStudySurveyDto = {
  createdAt: string;
  updatedAt: string;
  questions: GetStudySurveyQuestionDto[];
  _id: string;
  title: string;
  sections: SurveySection[];
  type?: "onboarding" | "exit";
};
export type CreateSurveyQuestionDto = {
  questionType:
    | "single-choice"
    | "multiple-choice"
    | "text-entry"
    | "text-graphic";
  questionText: string;
  format:
    | "single-select"
    | "multi-select"
    | "single-select-with-other"
    | "multi-select-with-other"
    | "dropdown"
    | "dropdown-with-other"
    | "single-line"
    | "multiple-lines"
    | "essay-text-box"
    | "text"
    | "graphic"
    | "text-&-graphic";
  options?: string[];
  sortOrder?: number;
  image?: string;
  randomizeQuestionOptions?: boolean;
  required?: boolean;
  section: number;
  conditions: SurveyQuestionCondition[];
};
export type CreateSurveyDto = {
  title: string;
  sections: SurveySection[];
  questions: CreateSurveyQuestionDto[];
  type?: "onboarding" | "exit";
};
export type GetStudySurveyDtoPaginatedReturn = {
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page?: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage?: number;
  nextPage?: number;
  docs: GetStudySurveyDto[];
};
export type UpdateSurveyQuestion = {
  _id?: string;
  questionType:
    | "single-choice"
    | "multiple-choice"
    | "text-entry"
    | "text-graphic";
  questionText: string;
  format:
    | "single-select"
    | "multi-select"
    | "single-select-with-other"
    | "multi-select-with-other"
    | "dropdown"
    | "dropdown-with-other"
    | "single-line"
    | "multiple-lines"
    | "essay-text-box"
    | "text"
    | "graphic"
    | "text-&-graphic";
  options?: string[];
  sortOrder?: number;
  image?: string;
  randomizeQuestionOptions?: boolean;
  required?: boolean;
  section: number;
  conditions: SurveyQuestionCondition[];
};
export type UpdateSurveyDto = {
  questions: UpdateSurveyQuestion[];
  title: string;
  sections: SurveySection[];
  type?: "onboarding" | "exit";
};
export type SurveyResponse = {
  _id: string;
  user: string;
  survey: string;
  createdAt: string;
};
export type CreateSurveyQuestionAnswerDto = {
  question: string;
  answer: string[];
  optionsOrder: string[];
};
export type CreateSurveyResponseDto = {
  answers: CreateSurveyQuestionAnswerDto[];
};
export type GetSurveyQuestionResponseAnswer = {
  _id: string;
  surveyResponse: string;
  answer: string[];
  optionsOrder: string[];
  question: CreateSurveyQuestionDto;
};
export type PromptOverride = {
  prompt: string;
  botId: string;
  type: "post" | "comment" | "interaction";
};
export type PostLabel = {
  _id: string;
  study: string;
  title: string;
  isTitleHidden?: boolean;
  imageUrl?: string;
  color?: string;
  createdAt: string;
  updatedAt: string;
  duplicatedFromId?: string;
};
export type ParticipantProfileValue = {
  _id: string;
  profile: string;
  name:
    | "first-name"
    | "last-name"
    | "bio/description"
    | "location"
    | "political-affiliation"
    | "age"
    | "gender"
    | "race"
    | "household-income"
    | "profile-picture";
  value: string | number | boolean;
  type?: string;
};
export type ParticipantProfileWithPropertiesDto = {
  _id: string;
  study: string;
  user?: string;
  treatmentArm?: string;
  tags?: string[];
  postPrompt?: string;
  commentPrompt?: string;
  interactionPrompt?: string;
  qualified: boolean;
  profileNumber?: string;
  participantCode?: string;
  followers: number;
  following: number;
  exitSurveySchedule?: string;
  finishedOnboardingSurveyAt?: string;
  enteredNewsfeedAt?: string;
  isAutoFollowingDisabled?: boolean;
  duplicatedFromId?: string;
  blockedProfiles?: string[];
  treatmentArmRestrictions?: object;
  promptOverrides?: PromptOverride[];
  labels?: PostLabel[];
  highlight?: PostLabel;
  properties: ParticipantProfileValue[];
};
export type GetSurveyResponseUser = {
  _id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  roles: ("ADMIN" | "RESEARCHER" | "PARTICIPANT")[];
  profilePicture?: string;
  currentStudy?: string;
  currentTreatmentArm?: string;
  university?: string;
  deviceIds?: string[];
  createdAt: string;
  updatedAt: string;
  profile: ParticipantProfileWithPropertiesDto;
};
export type GetSurveyResponseDto = {
  _id: string;
  survey: string;
  createdAt: string;
  updatedAt: string;
  answers: GetSurveyQuestionResponseAnswer[];
  user: GetSurveyResponseUser;
};
export type GetSurveyResponseDtoPaginatedReturn = {
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page?: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage?: number;
  nextPage?: number;
  docs: GetSurveyResponseDto[];
};
export type User = {
  _id: string;
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  roles: ("ADMIN" | "RESEARCHER" | "PARTICIPANT")[];
  profilePicture?: string;
  currentStudy?: string;
  currentTreatmentArm?: string;
  university?: string;
  deviceIds?: string[];
  createdAt: string;
  updatedAt: string;
};
export type ParticipantProfileWithPropertiesWithUserDto = {
  _id: string;
  study: string;
  treatmentArm?: string;
  tags?: string[];
  postPrompt?: string;
  commentPrompt?: string;
  interactionPrompt?: string;
  qualified: boolean;
  profileNumber?: string;
  participantCode?: string;
  followers: number;
  following: number;
  exitSurveySchedule?: string;
  finishedOnboardingSurveyAt?: string;
  enteredNewsfeedAt?: string;
  isAutoFollowingDisabled?: boolean;
  duplicatedFromId?: string;
  blockedProfiles?: string[];
  treatmentArmRestrictions?: object;
  promptOverrides?: PromptOverride[];
  labels?: PostLabel[];
  highlight?: PostLabel;
  user?: User;
  properties: ParticipantProfileValue[];
};
export type ParticipantProfileWithPropertiesWithUserDtoPaginatedReturn = {
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page?: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage?: number;
  nextPage?: number;
  docs: ParticipantProfileWithPropertiesWithUserDto[];
};
export type GenerateSimulatedProfilesProperty = {
  options: object;
  name:
    | "first-name"
    | "last-name"
    | "bio/description"
    | "location"
    | "political-affiliation"
    | "age"
    | "gender"
    | "race"
    | "household-income"
    | "profile-picture";
};
export type GenerateSimulatedProfilesDto = {
  nameType?: "regular" | "username";
  amount: number;
  properties: GenerateSimulatedProfilesProperty[];
  tags?: string[];
  followersDistribution?: number[];
  followingDistribution?: number[];
};
export type CreateParticipantProfileValueDto = {
  value: string | number | boolean;
  profile: string;
  name:
    | "first-name"
    | "last-name"
    | "bio/description"
    | "location"
    | "political-affiliation"
    | "age"
    | "gender"
    | "race"
    | "household-income"
    | "profile-picture";
};
export type UpdateProfileDto = {
  labels?: string[];
  highlight?: string | null;
  promptOverrides?: PromptOverride[];
  tags?: string[];
  postPrompt?: string;
  commentPrompt?: string;
  interactionPrompt?: string;
  properties?: CreateParticipantProfileValueDto[];
  qualified?: boolean;
  exitSurveySchedule?: string;
  enteredNewsfeedAt?: string;
};
export type PostReturnDto = {
  date?: string;
  user?: string;
  onlyForUser?: string;
  profile: ParticipantProfileWithPropertiesDto;
  content: string;
  tags?: string[];
  link?: string;
  likes?: number;
  dislikes?: number;
  interactionVariants?: InteractionVariantsEntity[];
  mediaUrls: string[];
  relativeTime?: RelativeTime;
  isMadeByResearcher?: boolean;
  customInteractionsCount?: object;
  duplicatedFromId?: string;
  taggedProfiles?: TaggedProfilesEntity[];
  _id: string;
  interactionByMe?: string;
  interactionAffiliation?: string;
  labels?: PostLabel[];
  highlight?: PostLabel;
};
export type PreviewPostTags = {
  type: "show" | "hide";
  value: string[];
};
export type PreviewPopulatedPostsDto = {
  postsIds: string[];
  tags?: PreviewPostTags;
  excludedBotProfileIds: string[];
};
export type PreviewPostsDestinationDto = {
  tags?: PreviewPostTags;
  excludedBotProfileIds: string[];
  pastPostsIds: string[];
  futurePostsIds: string[];
};
export type CreatePostDto = {
  date?: string;
  user?: string;
  onlyForUser?: string;
  profile: string;
  content: string;
  tags?: string[];
  link?: string;
  likes?: number;
  dislikes?: number;
  interactionVariants?: InteractionVariantsEntity[];
  mediaUrls: string[];
  relativeTime?: RelativeTime;
  isMadeByResearcher?: boolean;
  labels?: string[];
  highlight?: string | null;
  customInteractionsCount?: object;
  duplicatedFromId?: string;
  taggedProfiles?: TaggedProfilesEntity[];
};
export type PostReturnDtoPaginatedReturn = {
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page?: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage?: number;
  nextPage?: number;
  docs: PostReturnDto[];
};
export type CommentReturnDto = {
  date: string;
  user?: string;
  profile: ParticipantProfileWithPropertiesDto;
  content: string;
  postInstance?: string;
  mediaUrls: string[];
  isMadeByResearcher?: boolean;
  taggedProfiles?: TaggedProfilesEntity[];
  _id: string;
};
export type CommentReturnDtoPaginatedReturn = {
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page?: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage?: number;
  nextPage?: number;
  docs: CommentReturnDto[];
};
export type CreateCommentDto = {
  date: string;
  user?: string;
  profile: string;
  content: string;
  postInstance?: string;
  mediaUrls: string[];
  isMadeByResearcher?: boolean;
  taggedProfiles?: TaggedProfilesEntity[];
};
export type Comment2 = {
  _id: string;
  date: string;
  user?: string;
  profile: string;
  content: string;
  postInstance?: string;
  mediaUrls: string[];
  isMadeByResearcher?: boolean;
  taggedProfiles?: TaggedProfilesEntity[];
};
export type UpdateCommentDto = {
  date?: string;
  user?: string;
  profile?: string;
  content?: string;
  postInstance?: string;
  mediaUrls?: string[];
  isMadeByResearcher?: boolean;
  taggedProfiles?: TaggedProfilesEntity[];
};
export type Interaction = {
  _id: string;
  post: string;
  profile: string;
  user: string;
  action: string;
  affiliation?: string;
  createdAt: string;
  updatedAt: string;
};
export type CreateInteractionDto = {
  action: string;
  profile: string;
  affiliation: string;
};
export type ProfileVariantEntity = {
  _id?: string;
  name:
    | "first-name"
    | "last-name"
    | "bio/description"
    | "location"
    | "political-affiliation"
    | "age"
    | "gender"
    | "race"
    | "household-income"
    | "profile-picture";
  value: "hidden" | "shown" | "enabled" | "disabled";
};
export type InteractionVariantEntity = {
  _id?: string;
  name: string;
  value: "hidden" | "shown" | "enabled" | "disabled";
};
export type ContentVariantEntity = {
  _id?: string;
  name:
    | "show-hide-content"
    | "follow-unfollow-from-bots"
    | "interaction-from-bots";
  value: "hidden" | "shown" | "enabled" | "disabled";
  tags?: string[];
};
export type EvergreenTime = {
  amount: number;
  unit: "months" | "weeks" | "days" | "hours" | "minutes";
};
export type PrePopulatedPost = {
  _id?: string;
  post: string;
  type: "past" | "future";
};
export type ConfiguredPopup = {
  _id?: string;
  popup: string;
  type: "reactive" | "time-based";
  meta?: object;
  promptOverrides?: PromptOverride[];
};
export type TreatmentArm = {
  _id: string;
  title: string;
  instructions?: string;
  distribution?: number;
  study: string;
  profileVariants: ProfileVariantEntity[];
  interactionVariants: InteractionVariantEntity[];
  contentVariants: ContentVariantEntity[];
  evergreenTime: EvergreenTime;
  prePopulatedPosts?: PrePopulatedPost[];
  configuredPopups?: ConfiguredPopup[];
  excludedBotProfiles?: string[];
  postPromptPlaceholderText?: string;
  postSubmitIconUrl?: string;
};
export type TreatmentArmBotRestrictions = {
  id: string;
  canEngage: boolean;
  canPost: boolean;
  canComment: boolean;
  canConnect: boolean;
};
export type CreateTreatmentArmDto = {
  title: string;
  instructions?: string;
  distribution?: number;
  profileVariants: ProfileVariantEntity[];
  interactionVariants: InteractionVariantEntity[];
  contentVariants: ContentVariantEntity[];
  evergreenTime: EvergreenTime;
  prePopulatedPosts?: PrePopulatedPost[];
  configuredPopups?: ConfiguredPopup[];
  excludedBotProfiles?: string[];
  postPromptPlaceholderText?: string;
  postSubmitIconUrl?: string;
  botRestrictions?: TreatmentArmBotRestrictions[];
};
export type TreatmentArmWithParticipantsCountDto = {
  _id: string;
  title: string;
  instructions?: string;
  distribution?: number;
  study: string;
  profileVariants: ProfileVariantEntity[];
  interactionVariants: InteractionVariantEntity[];
  contentVariants: ContentVariantEntity[];
  evergreenTime: EvergreenTime;
  prePopulatedPosts?: PrePopulatedPost[];
  configuredPopups?: ConfiguredPopup[];
  excludedBotProfiles?: string[];
  postPromptPlaceholderText?: string;
  postSubmitIconUrl?: string;
  numberOfParticipants: number;
};
export type TreatmentArmWithParticipantsCountDtoPaginatedReturn = {
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page?: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage?: number;
  nextPage?: number;
  docs: TreatmentArmWithParticipantsCountDto[];
};
export type PrePopulatedPostDto = {
  _id?: string;
  post: PostReturnDto;
  type: "past" | "future";
};
export type Survey = {
  name: string;
  id: string;
};
export type Popup = {
  _id: string;
  study: string;
  title: string;
  content?: string | null;
  survey?: Survey | null;
  continueActionType?: "time" | "button" | "checkbox";
  continueActionMeta?: object;
  createdAt: string;
  updatedAt: string;
};
export type ConfiguredPopupDto = {
  _id?: string;
  type: "reactive" | "time-based";
  meta?: object;
  promptOverrides?: PromptOverride[];
  popup: Popup;
};
export type TreatmentArmWithPostsResponseDto = {
  _id: string;
  title: string;
  instructions?: string;
  distribution?: number;
  study: string;
  profileVariants: ProfileVariantEntity[];
  interactionVariants: InteractionVariantEntity[];
  contentVariants: ContentVariantEntity[];
  evergreenTime: EvergreenTime;
  excludedBotProfiles?: string[];
  postPromptPlaceholderText?: string;
  postSubmitIconUrl?: string;
  prePopulatedPosts: PrePopulatedPostDto[];
  configuredPopups: ConfiguredPopupDto[];
};
export type Distribution = {
  treatmentArmId: string;
  value: number;
};
export type UpdateTreatmentArmDistributionDto = {
  distributions: Distribution[];
};
export type ParticipantProfile = {
  _id: string;
  study: string;
  user?: string;
  treatmentArm?: string;
  tags?: string[];
  postPrompt?: string;
  commentPrompt?: string;
  interactionPrompt?: string;
  qualified: boolean;
  profileNumber?: string;
  participantCode?: string;
  followers: number;
  following: number;
  exitSurveySchedule?: string;
  finishedOnboardingSurveyAt?: string;
  enteredNewsfeedAt?: string;
  isAutoFollowingDisabled?: boolean;
  duplicatedFromId?: string;
  blockedProfiles?: string[];
  treatmentArmRestrictions?: object;
  promptOverrides?: PromptOverride[];
};
export type JoinStudyDto = {
  invitationCode: string;
  participantCode: string;
};
export type ParticipantCodeResponseDto = {
  _id: string;
  code: string;
  study: string;
  isArmAssignmentBasedOnSurvey?: boolean;
  status: "available" | "used" | "expired";
  user?: ObjectId;
  isUsedForOnboardingSurvey?: boolean;
  isUsedForExitSurvey?: boolean;
  treatmentArm: TreatmentArm;
};
export type ParticipantCodeResponseDtoPaginatedReturn = {
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page?: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage?: number;
  nextPage?: number;
  docs: ParticipantCodeResponseDto[];
};
export type ParticipantCode = {
  _id: string;
  code: string;
  study: string;
  treatmentArm?: string;
  isArmAssignmentBasedOnSurvey?: boolean;
  status: "available" | "used" | "expired";
  user?: ObjectId;
  isUsedForOnboardingSurvey?: boolean;
  isUsedForExitSurvey?: boolean;
};
export type GenerateParticipantCodesDto = {
  amount: number;
  treatmentArm?: string;
  isArmAssignmentBasedOnSurvey?: boolean;
};
export type BotPrompt = {
  _id: string;
  beforeTags: string;
  afterTags: string;
  promptType: string;
  isActive?: boolean;
};
export type CreateBotPromptDto = {
  beforeTags: string;
  afterTags: string;
  promptType: string;
  isActive?: boolean;
};
export type UpdateBotPromptDto = {
  beforeTags?: string;
  afterTags?: string;
  promptType?: string;
  isActive?: boolean;
};
export type Following = {
  _id: string;
  createdAt: string;
  updatedAt: string;
  profile: string;
  follower: string;
};
export const {
  useStudiesControllerGetStudiesQuery,
  useLazyStudiesControllerGetStudiesQuery,
  useStudiesControllerCreateStudyMutation,
  useStudiesControllerPatchStudyMutation,
  useStudiesControllerGetStudyQuery,
  useLazyStudiesControllerGetStudyQuery,
  useStudiesControllerDeleteStudyMutation,
  useStudiesControllerDuplicateMutation,
  useStudiesControllerGetStudyParticipantsQuery,
  useLazyStudiesControllerGetStudyParticipantsQuery,
  useStudiesControllerGetParticipantsDataByStudyQuery,
  useLazyStudiesControllerGetParticipantsDataByStudyQuery,
  useStudiesControllerGetParticipantsDataByStudyInCsvQuery,
  useLazyStudiesControllerGetParticipantsDataByStudyInCsvQuery,
  useStudiesControllerFindStudyResearcherPostsQuery,
  useLazyStudiesControllerFindStudyResearcherPostsQuery,
  useStudiesControllerResetResearcherPostsMutation,
  useStudiesControllerHandleExportAnalyticsQuery,
  useLazyStudiesControllerHandleExportAnalyticsQuery,
  useStudiesControllerHandleOverrideMutation,
  useStudiesControllerCreateStudySurveyMutation,
  useStudiesControllerGetStudySurveysQuery,
  useLazyStudiesControllerGetStudySurveysQuery,
  useStudiesControllerGetSurveyQuery,
  useLazyStudiesControllerGetSurveyQuery,
  useStudiesControllerUpdateSurveyMutation,
  useStudiesControllerDeleteStudySurveyMutation,
  useStudiesControllerCreateStudySurveyResponseMutation,
  useStudiesControllerGetStudySurveyResponsesQuery,
  useLazyStudiesControllerGetStudySurveyResponsesQuery,
  useStudiesControllerGetProfilesQuery,
  useLazyStudiesControllerGetProfilesQuery,
  useStudiesControllerGenerateSimulatedProfilesMutation,
  useStudiesControllerUpdateProfileMutation,
  useStudiesControllerDeleteStudyProfileMutation,
  useStudiesControllerPreviewPopulatedPostMutation,
  useStudiesControllerPreviewPostsDestinationMutation,
  useStudiesControllerCreatePostMutation,
  useStudiesControllerFindAllPostsQuery,
  useLazyStudiesControllerFindAllPostsQuery,
  useStudiesControllerFindPostByIdQuery,
  useLazyStudiesControllerFindPostByIdQuery,
  useStudiesControllerUpdatePostMutation,
  useStudiesControllerDeletePostMutation,
  useStudiesControllerFindPostCommentsByPostIdQuery,
  useLazyStudiesControllerFindPostCommentsByPostIdQuery,
  useStudiesControllerCreateCommentForPostMutation,
  useStudiesControllerUpdateCommentForPostMutation,
  useStudiesControllerDeleteCommentMutation,
  useStudiesControllerUpsertInteractionForPostMutation,
  useStudiesControllerCreateTreatmentArmMutation,
  useStudiesControllerGetTreatmentArmsQuery,
  useLazyStudiesControllerGetTreatmentArmsQuery,
  useStudiesControllerGetTreatmentArmQuery,
  useLazyStudiesControllerGetTreatmentArmQuery,
  useStudiesControllerUpdateTreatmentArmMutation,
  useStudiesControllerDeleteTreatmentArmMutation,
  useStudiesControllerUpdateTreatmentArmsDistributionsMutation,
  useStudiesControllerJoinStudyMutation,
  useStudiesControllerGetStudyParticipantCodesQuery,
  useLazyStudiesControllerGetStudyParticipantCodesQuery,
  useStudiesControllerGenerateParticipantCodesMutation,
  useStudiesControllerGetParticipantCodesAsCsvQuery,
  useLazyStudiesControllerGetParticipantCodesAsCsvQuery,
  useStudiesControllerCreateStudyBotPromptMutation,
  useStudiesControllerGetStudyBotPromptsQuery,
  useLazyStudiesControllerGetStudyBotPromptsQuery,
  useStudiesControllerUpdateBotPromptMutation,
  useStudiesControllerDeleteBotPromptMutation,
  useStudiesControllerCreateBotPostMutation,
  useStudiesControllerCreateBotCommentMutation,
  useStudiesControllerGenerateFollowingActionMutation,
  useStudiesControllerGenerateBotInteractionMutation,
  useStudiesControllerGetParticipantCodeQuery,
  useLazyStudiesControllerGetParticipantCodeQuery,
} = injectedRtkApi;
